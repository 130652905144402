import React from 'react';
import './PrivacyPolicy.css';

const PrivacyPolicy = () => {
  const data = {
    lastUpdated: 'April 17, 2024',

    // not yet functional
    tableOfContents: [
      "1. Introduction",
      "2. Interpretation and Definitions",
      "3. Types of Data Collected",
      "4. Use of Your Personal Data",
      "5. Retention of Your Personal Data",
      "6. Transfer of Your Personal Data",
      "7. Disclosure of Your Personal Data",
      "8. Data Subject Rights",
      "9. Data Breach Notification",
      "10. Compliance with Regulations",
      "11. Non-Support of GDPR and CCPA",
      "12. Changes to this Privacy Policy",
      "13. Contact Us",
    ],

    sections: [
      {
        title: "Introduction",
        content: [
          "This Privacy Policy describes Our policies and procedures on the collection, use, and disclosure of Your information when You use the Service and tells You about Your privacy rights and how the law protects You.",
          "We use Your Personal data to provide and improve the Service. By using the Service, You agree to the collection and use of information in accordance with this Privacy Policy. This Privacy Policy has been created with the help of the Privacy Policy Generator."
        ],
        links: [
          {
            text: "Privacy Policy Generator",
            href: "https://www.termsfeed.com/privacy-policy-generator/"
          }
        ]
      },
      {
        title: "Interpretation and Definitions",
        subSections: [
          {
            title: "Interpretation",
            content: [
              "The words of which the initial letter is capitalized have meanings defined under the following conditions. The following definitions shall have the same meaning regardless of whether they appear in singular or in plural."
            ]
          },
          {
            title: "Definitions",
            content: [
              "For the purposes of this Privacy Policy:"
            ],
            definitions: [
              { term: "Account", definition: "A unique account created for You to access our Service or parts of our Service." },
              { term: "Affiliate", definition: "An entity that controls, is controlled by, or is under common control with a party, where 'control' means ownership of 50% or more of the shares, equity interest, or other securities entitled to vote for election of directors or other managing authority." },
              { term: "Company", definition: "(referred to as either 'the Company', 'We', 'Us' or 'Our' in this Agreement) refers to Resume Co-Pilot, 318 Meadow Brook Rd, Rochester, MI 48309." },
              { term: "Cookies", definition: "Small files that are placed on Your computer, mobile device or any other device by a website, containing the details of Your browsing history on that website among its many uses." },
              { term: "Country", definition: "Michigan, United States" },
              { term: "Device", definition: "Any device that can access the Service such as a computer, a cellphone, or a digital tablet." },
              { term: "Personal Data", definition: "Any information that relates to an identified or identifiable individual." },
              { term: "Service", definition: "Refers to the Website." },
              { term: "Service Provider", definition: "Any natural or legal person who processes the data on behalf of the Company. It refers to third-party companies or individuals employed by the Company to facilitate the Service, to provide the Service on behalf of the Company, to perform services related to the Service or to assist the Company in analyzing how the Service is used." },
              { term: "Third-party Social Media Service", definition: "Any website or any social network website through which a User can log in or create an account to use the Service." },
              { term: "Usage Data", definition: "Data collected automatically, either generated by the use of the Service or from the Service infrastructure itself (for example, the duration of a page visit)." },
              { term: "Website", definition: "Refers to Resume Co-Pilot, accessible from https://resumecopilot.us" },
              { term: "You", definition: "The individual accessing or using the Service, or the company, or other legal entity on behalf of which such individual is accessing or using the Service, as applicable." },
            ]
          }
        ]
      },
      {
      title: "Collecting and Using Your Personal Data",
        subSections: [
          {
            title: "Types of Data Collected",
            content: [
              "We collect various types of Personal Data and other information as outlined below:",
              "- Identifiers: contact details, usernames, account details",
              "- Personal information: name, email, phone number, personal characteristics",
              "- Protected characteristics: gender, date of birth",
              "- Commercial information",
              "- Biometric information",
              "- Internet or other network activity: browsing history, cookies, interaction data",
              "- Geolocation data",
              "- Professional or employment-related information",
              "- Education information",
              "- Inferences drawn from other personal data collected to create a profile about preferences, behavior, abilities, etc."
            ],
          },
          {
            title: "Usage Data",
            content: [
              "The Company may use Personal Data for the following purposes:",
              "- To provide and maintain our Service, including to monitor the usage of our Service.",
              "- To manage Your Account: to manage Your registration as a user of the Service.",
              "- For the performance of a contract: the development, compliance, and undertaking of the purchase contract for the products, items, or services You have purchased or of any other contract with Us through the Service.",
              "- To contact You: To contact You by email, telephone calls, SMS, or other equivalent forms of electronic communication, such as a mobile application's push notifications regarding updates or informative communications related to the functionalities, products or contracted services, including the security updates, when necessary or reasonable for their implementation.",
              "- For marketing and promotional purposes",
              "- For internal analysis and to improve the Service",
              "- To comply with legal obligations"
            ],
          },
          {
            title: "Information from Third-Party Social Media Services",
            content: [
              "The Company allows You to create an account and log in to use the Service through the following Third-party Social Media Services: Google, Facebook, Instagram, Twitter, LinkedIn.",
              "If You decide to register through or otherwise grant us access to a Third-Party Social Media Service, We may collect Personal data that is already associated with Your Third-Party Social Media Service's account, such as Your name, Your email address, Your activities or Your contact list associated with that account.",
              "You may also have the option of sharing additional information with the Company through Your Third-Party Social Media Service's account. If You choose to provide such information and Personal Data, during registration or otherwise, You are giving the Company permission to use, share, and store it in a manner consistent with this Privacy Policy."
            ]
          },
          {
            title: "Tracking Technologies and Cookies",
            content: [
              "We use Cookies and similar tracking technologies to track the activity on Our Service and store certain information.",
              "Tracking technologies used are beacons, tags, and scripts to collect and track information and to improve and analyze Our Service. The technologies We use may include:",
              "Cookies or Browser Cookies. A cookie is a small file placed on Your Device. You can instruct Your browser to refuse all Cookies or to indicate when a Cookie is being sent. However, if You do not accept Cookies, You may not be able to use some parts of our Service. Unless you have adjusted Your browser setting so that it will refuse Cookies, our Service may use Cookies.",
              "Web Beacons. Certain sections of our Service and our emails may contain small electronic files known as web beacons (also referred to as clear gifs, pixel tags, and single-pixel gifs) that permit the Company, for example, to count users who have visited those pages or opened an email and for other related website statistics (for example, recording the popularity of a certain section and verifying system and server integrity)."
            ],
            additionalInfo: "You can learn more about cookies on the TermsFeed website article linked below.",
            link: {
              text: "Learn more about cookies",
              href: "https://www.termsfeed.com/blog/cookies/#What_Are_Cookies"
            }
        },
        {
          title: "Use of Your Personal Data",
          content: [
            "The Company may use Personal Data for the following purposes:",
            "To provide and maintain our Service, including to monitor the usage of our Service.",
            "To manage Your Account: to manage Your registration as a user of the Service. The Personal Data You provide can give You access to different functionalities of the Service that are available to You as a registered user.",
            "For the performance of a contract: the development, compliance, and undertaking of the purchase contract for the products, items, or services You have purchased or of any other contract with Us through the Service.",
            "To contact You: To contact You by email, telephone calls, SMS, or other equivalent forms of electronic communication, such as a mobile application's push notifications regarding updates or informative communications related to the functionalities, products or contracted services, including the security updates, when necessary or reasonable for their implementation.",
            "To provide You with news, special offers, and general information about other goods, services, and events which we offer that are similar to those that you have already purchased or enquired about unless You have opted not to receive such information.",
            "To manage Your requests: To attend and manage Your requests to Us.",
            "For business transfers: We may use Your information to evaluate or conduct a merger, divestiture, restructuring, reorganization, dissolution, or other sale or transfer of some or all of Our assets, whether as a going concern or as part of bankruptcy, liquidation, or similar proceeding, in which Personal Data held by Us about our Service users is among the assets transferred.",
            "For other purposes: We may use Your information for other purposes, such as data analysis, identifying usage trends, determining the effectiveness of our promotional campaigns and to evaluate and improve our Service, products, services, marketing and your experience."
          ],
          sharingScenarios: [
            "With Service Providers: We may share Your personal information with Service Providers to monitor and analyze the use of our Service, to contact You.",
            "For business transfers: We may share or transfer Your personal information in connection with, or during negotiations of, any merger, sale of Company assets, financing, or acquisition of all or a portion of Our business to another company.",
            "With Affiliates: We may share Your information with Our affiliates, in which case we will require those affiliates to honor this Privacy Policy. Affiliates include Our parent company and any other subsidiaries, joint venture partners, or other companies that We control or that are under common control with Us.",
            "With business partners: We may share Your information with Our business partners to offer You certain products, services or promotions.",
            "With other users: when You share personal information or otherwise interact in the public areas with other users, such information may be viewed by all users and may be publicly distributed outside. If You interact with other users or register through a Third-Party Social Media Service, Your contacts on the Third-Party Social Media Service may see Your name, profile, pictures and description of Your activity. Similarly, other users will be able to view descriptions of Your activity, communicate with You and view Your profile.",
            "With Your consent: We may disclose Your personal information for any other purpose with Your consent."
          ]
        }
      ]
    },
    {
      title: "Retention of Your Personal Data",
      content: [
        "The Company will retain Your Personal Data only for as long as is necessary for the purposes set out in this Privacy Policy. We will retain and use Your Personal Data to the extent necessary to comply with our legal obligations (for example, if we are required to retain your data to comply with applicable laws), resolve disputes, and enforce our legal agreements and policies."
      ]
    },
    {
      title: "Transfer of Your Personal Data",
      content: [
        "Your information, including Personal Data, may be transferred to — and maintained on — computers located outside of Your state, province, country or other governmental jurisdiction where the data protection laws may differ from those from Your jurisdiction."
      ]
    },
    {
      title: "Disclosure of Your Personal Data",
      subSections: [
        {
          title: "Business Transactions",
          content: [
            "If the Company is involved in a merger, acquisition or asset sale, Your Personal Data may be transferred. We will provide notice before Your Personal Data is transferred and becomes subject to a different Privacy Policy."
          ]
        },
        {
          title: "Law Enforcement",
          content: [
            "Under certain circumstances, the Company may be required to disclose Your Personal Data if required to do so by law or in response to valid requests by public authorities (e.g., a court or a government agency)."
          ]
        },
        {
          title: "Other Legal Requirements",
          content: [
            "The Company may disclose Your Personal Data in the good faith belief that such action is necessary to:",
            "Comply with a legal obligation",
            "Protect and defend the rights or property of the Company",
            "Prevent or investigate possible wrongdoing in connection with the Service",
            "Protect the personal safety of Users of the Service or the public",
            "Protect against legal liability"
          ]
        }
      ]
    },
    {
      title: "Delete Your Personal Data",
      content: [
        "You have the right to delete or request that We assist in deleting the Personal Data that We have collected about You.",
        "Our Service may give You the ability to delete certain information about You from within the Service.",
        "You may update, amend, or delete Your information at any time by signing in to Your Account, if you have one, and visiting the account settings section that allows you to manage Your personal information. You may also contact Us to request access to, correct, or delete any personal information that You have provided to Us.",
        "Please note, however, that We may need to retain certain information when we have a legal obligation or lawful basis to do so."
      ]
    },
    {
      title: "Opt-out Options",
      content: [
        "You may opt-out of receiving promotional communications from Us at any time by following the instructions provided in those communications or by contacting us.",
        "Even if you opt-out, we may still contact you for transactional or informational purposes related to your account and the Service.",
        "You can also opt-out of certain tracking technologies we use, such as cookies, by adjusting your browser settings. Please refer to your browser's help section for more information on how to manage cookies."
      ]
    },
    {
      title: "Data Subject Rights",
      content: [
        "In accordance with applicable privacy laws, You have the following rights with respect to Your Personal Data:",
        "- The right to access: You have the right to request copies of Your Personal Data.",
        "- The right to rectification: You have the right to request that We correct any information You believe is inaccurate. You also have the right to request that We complete the information You believe is incomplete.",
        "- The right to erasure: You have the right to request that We erase Your Personal Data, under certain conditions.",
        "- The right to restrict processing: You have the right to request that We restrict the processing of Your Personal Data, under certain conditions.",
        "- The right to object to processing: You have the right to object to Our processing of Your Personal Data, under certain conditions.",
        "- The right to data portability: You have the right to request that We transfer the data that we have collected to another organization, or directly to You, under certain conditions.",
        "If you make a request, we have one month to respond to you. If you would like to exercise any of these rights, please contact us."
      ]
    },
    {
      title: "Data Breach Notification",
      content: [
        "In the event of a data breach involving Your Personal Data, we will notify You and any applicable regulatory authorities as required by law.",
        "We will provide this notification as soon as practicable and without undue delay."
      ]
    },
    {
      title: "Compliance with Regulations",
      content: [
        "We are committed to protecting Your privacy and complying with all applicable data protection and privacy laws when feasible.",
        "We will update this Privacy Policy as needed to maintain compliance with any changes to these or other relevant laws and regulations."
      ]
    },
    {
      title: "Non-Support of GDPR and CCPA",
      content: [
        "We explicitly do not support the General Data Protection Regulation (GDPR) and the California Consumer Privacy Act (CCPA). As such, our platform and services are not intended for use by individuals located within the European Union or the State of California.",
        "We discourage the use of our Service by individuals from these regions and do not design our data practices to comply with these regulations. If you are from the European Union or California, please be advised that by using our Service, you acknowledge and agree to our data practices which are not compliant with GDPR and CCPA."
      ],
    },
    {
      title: "Changes to this Privacy Policy",
      content: [
        "We may update Our Privacy Policy from time to time. We will notify You of any changes by posting the new Privacy Policy on this page.",
        "We will let You know via email and/or a prominent notice on Our Service, prior to the change becoming effective and update the 'Last updated' date at the top of this Privacy Policy.",
        "You are advised to review this Privacy Policy periodically for any changes. Changes to this Privacy Policy are effective when they are posted on this page."
      ]
    },
    {
      title: "Contact Us",
      content: [
        "If you have any questions about this Privacy Policy, You can contact us:",
        "By email: privacy@resumecopilot.us",
        "By visiting this page on our website: https://resumecopilot.us/privacy-policy"
      ],
      links: [
        {
          text: "Email Us",
          href: "mailto:privacy@resumecopilot.us"
        },
        {
          text: "Privacy Policy",
          href: "https://resumecopilot.us/privacy-policy"
        }
      ]
    },
      // add more here  
  ]
};

  return (
    <div className="privacy-policy-container">
      <h1>Privacy Policy</h1>
      <p>Last updated: {data.lastUpdated}</p>
      {data.sections.map((section, sectionIndex) => (
        <div key={sectionIndex} className="section">
          <h2>{section.title}</h2>
          {section.content && section.content.map((paragraph, pIndex) => (
            <p key={pIndex}>{paragraph}</p>
          ))}
          {section.links && section.links.map((link, linkIndex) => (
            <p key={linkIndex}><a href={link.href} target="_blank" rel="noopener noreferrer">{link.text}</a></p>
          ))}
          {section.subSections && section.subSections.map((subSection, subIndex) => (
            <div key={subIndex} className="sub-section">
              <h3>{subSection.title}</h3>
              {subSection.content && subSection.content.map((subParagraph, spIndex) => (
                <p key={spIndex}>{subParagraph}</p>
              ))}
              {subSection.definitions && subSection.definitions.map((def, defIndex) => (
                <p key={defIndex}><strong>{def.term}:</strong> {def.definition}</p>
              ))}
            </div>
          ))}
        </div>
      ))}
    </div>
  );
};

export default PrivacyPolicy;
