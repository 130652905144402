// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.preferences-container {
    max-width: 600px;
    margin: 20px auto;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 8px;
    background-color: #004d69;
    box-shadow: 0 4px 6px rgba(0,0,0,0.1);
}

.preferences-info {
    margin-bottom: 20px;
}

.note-box {
    padding: 10px;
    background-color: #fffbe6;  /* Light yellow background */
    border-left: 5px solid #ffc107;  /* Highlighting border on the left */
    color: #333;
    font-style: italic;
}

.note-box p {
    margin: 0;  /* Remove default paragraph margin */
}
`, "",{"version":3,"sources":["webpack://./src/components/ChangePreferences.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;IAChB,iBAAiB;IACjB,aAAa;IACb,sBAAsB;IACtB,kBAAkB;IAClB,yBAAyB;IACzB,qCAAqC;AACzC;;AAEA;IACI,mBAAmB;AACvB;;AAEA;IACI,aAAa;IACb,yBAAyB,GAAG,4BAA4B;IACxD,8BAA8B,GAAG,oCAAoC;IACrE,WAAW;IACX,kBAAkB;AACtB;;AAEA;IACI,SAAS,GAAG,oCAAoC;AACpD","sourcesContent":[".preferences-container {\n    max-width: 600px;\n    margin: 20px auto;\n    padding: 20px;\n    border: 1px solid #ccc;\n    border-radius: 8px;\n    background-color: #004d69;\n    box-shadow: 0 4px 6px rgba(0,0,0,0.1);\n}\n\n.preferences-info {\n    margin-bottom: 20px;\n}\n\n.note-box {\n    padding: 10px;\n    background-color: #fffbe6;  /* Light yellow background */\n    border-left: 5px solid #ffc107;  /* Highlighting border on the left */\n    color: #333;\n    font-style: italic;\n}\n\n.note-box p {\n    margin: 0;  /* Remove default paragraph margin */\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
