// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `h1 {
    text-align: center;
    font-size: 35px;
}
.specific-class li {
    color: #1DB8D4;
    font-size: 25px;
}
.facts{
    color: white;
    text-align: center;
    font-size: 18px;
}
.description{
    text-align: center;
    font-size: 25px;
}
.explanation {
    font-size: 18px;

}`, "",{"version":3,"sources":["webpack://./src/components/TipsAndTricks.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,eAAe;AACnB;AACA;IACI,cAAc;IACd,eAAe;AACnB;AACA;IACI,YAAY;IACZ,kBAAkB;IAClB,eAAe;AACnB;AACA;IACI,kBAAkB;IAClB,eAAe;AACnB;AACA;IACI,eAAe;;AAEnB","sourcesContent":["h1 {\n    text-align: center;\n    font-size: 35px;\n}\n.specific-class li {\n    color: #1DB8D4;\n    font-size: 25px;\n}\n.facts{\n    color: white;\n    text-align: center;\n    font-size: 18px;\n}\n.description{\n    text-align: center;\n    font-size: 25px;\n}\n.explanation {\n    font-size: 18px;\n\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
