// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.privacy-policy-container {
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
  max-width: 800px;
  margin: 0 auto;
  padding: 40px;
  background-color: #f5f5f5;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  color: #000; /* Set default text color to black */
}

.privacy-policy-container h1 {
  font-size: 32px;
  font-weight: bold;
  margin-bottom: 20px;
}

.privacy-policy-container h2 {
  font-size: 24px;
  font-weight: bold;
  margin-top: 40px;
  margin-bottom: 20px;
}

.privacy-policy-container p,
.privacy-policy-container li { /* Group selectors for paragraphs and list items */
  font-size: 16px;
  line-height: 1.6;
  margin-bottom: 20px; /* Standard margin for paragraphs */
}

.privacy-policy-container li {
  margin-bottom: 10px; /* Specific margin for list items */
}

.privacy-policy-container ul {
  list-style-type: disc;
  margin-left: 20px;
  margin-bottom: 20px;
}

.section {
  margin-bottom: 40px;
  border-bottom: 1px solid #ddd;
  padding-bottom: 40px;
}

.section:last-child {
  border-bottom: none;
  padding-bottom: 0;
}
`, "",{"version":3,"sources":["webpack://./src/components/PrivacyPolicy.css"],"names":[],"mappings":"AAAA;EACE,2DAA2D;EAC3D,gBAAgB;EAChB,cAAc;EACd,aAAa;EACb,yBAAyB;EACzB,kBAAkB;EAClB,wCAAwC;EACxC,WAAW,EAAE,oCAAoC;AACnD;;AAEA;EACE,eAAe;EACf,iBAAiB;EACjB,mBAAmB;AACrB;;AAEA;EACE,eAAe;EACf,iBAAiB;EACjB,gBAAgB;EAChB,mBAAmB;AACrB;;AAEA;+BAC+B,kDAAkD;EAC/E,eAAe;EACf,gBAAgB;EAChB,mBAAmB,EAAE,mCAAmC;AAC1D;;AAEA;EACE,mBAAmB,EAAE,mCAAmC;AAC1D;;AAEA;EACE,qBAAqB;EACrB,iBAAiB;EACjB,mBAAmB;AACrB;;AAEA;EACE,mBAAmB;EACnB,6BAA6B;EAC7B,oBAAoB;AACtB;;AAEA;EACE,mBAAmB;EACnB,iBAAiB;AACnB","sourcesContent":[".privacy-policy-container {\n  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;\n  max-width: 800px;\n  margin: 0 auto;\n  padding: 40px;\n  background-color: #f5f5f5;\n  border-radius: 8px;\n  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);\n  color: #000; /* Set default text color to black */\n}\n\n.privacy-policy-container h1 {\n  font-size: 32px;\n  font-weight: bold;\n  margin-bottom: 20px;\n}\n\n.privacy-policy-container h2 {\n  font-size: 24px;\n  font-weight: bold;\n  margin-top: 40px;\n  margin-bottom: 20px;\n}\n\n.privacy-policy-container p,\n.privacy-policy-container li { /* Group selectors for paragraphs and list items */\n  font-size: 16px;\n  line-height: 1.6;\n  margin-bottom: 20px; /* Standard margin for paragraphs */\n}\n\n.privacy-policy-container li {\n  margin-bottom: 10px; /* Specific margin for list items */\n}\n\n.privacy-policy-container ul {\n  list-style-type: disc;\n  margin-left: 20px;\n  margin-bottom: 20px;\n}\n\n.section {\n  margin-bottom: 40px;\n  border-bottom: 1px solid #ddd;\n  padding-bottom: 40px;\n}\n\n.section:last-child {\n  border-bottom: none;\n  padding-bottom: 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
