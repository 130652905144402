// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.upload-form-container {
    width: 800px;
    margin: auto;
}

.drop-area {
    width: 100%;
    height: 50px;
    padding: 10px;
    background: #191213;
    text-align: center;
    border-radius: 20px;
    border: 2px dashed #ffffff;
    box-sizing: border-box;
    cursor: pointer;
}

.drop-area input {
    display: none;
}

.drop-area p {
    margin: 0;
    color: #ffffff;
}

button {
    display: block;
    margin: 20px auto;
    padding: 10px 20px;
    background-color: #007bff;
    color: #ffffff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

button:hover {
    background-color: #0056b3;
}`, "",{"version":3,"sources":["webpack://./src/components/UploadForm.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,YAAY;AAChB;;AAEA;IACI,WAAW;IACX,YAAY;IACZ,aAAa;IACb,mBAAmB;IACnB,kBAAkB;IAClB,mBAAmB;IACnB,0BAA0B;IAC1B,sBAAsB;IACtB,eAAe;AACnB;;AAEA;IACI,aAAa;AACjB;;AAEA;IACI,SAAS;IACT,cAAc;AAClB;;AAEA;IACI,cAAc;IACd,iBAAiB;IACjB,kBAAkB;IAClB,yBAAyB;IACzB,cAAc;IACd,YAAY;IACZ,kBAAkB;IAClB,eAAe;AACnB;;AAEA;IACI,yBAAyB;AAC7B","sourcesContent":[".upload-form-container {\n    width: 800px;\n    margin: auto;\n}\n\n.drop-area {\n    width: 100%;\n    height: 50px;\n    padding: 10px;\n    background: #191213;\n    text-align: center;\n    border-radius: 20px;\n    border: 2px dashed #ffffff;\n    box-sizing: border-box;\n    cursor: pointer;\n}\n\n.drop-area input {\n    display: none;\n}\n\n.drop-area p {\n    margin: 0;\n    color: #ffffff;\n}\n\nbutton {\n    display: block;\n    margin: 20px auto;\n    padding: 10px 20px;\n    background-color: #007bff;\n    color: #ffffff;\n    border: none;\n    border-radius: 5px;\n    cursor: pointer;\n}\n\nbutton:hover {\n    background-color: #0056b3;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
